import tipoentrjson from "@/assets/json/tipoentradas.json";
import tipopagjson from "@/assets/json/tipopagos.json";
import tipoevenjson from "@/assets/json/tipoeventos.json";
import tipomembrjson from "@/assets/json/tipomembresias.json";
import sexosjson from "@/assets/json/sexos.json";
import estadocarjson from "@/assets/json/estadocarnet.json";
import diasjson from "@/assets/json/dias.json";
const state = () => ({
 sexos: sexosjson,
  tipoEntradas: tipoentrjson,
  tipoPagos: tipopagjson,
  tipoEventos: tipoevenjson,
  tipoMembresias: tipomembrjson,
  estadoCarnet:  estadocarjson,
  dias: diasjson
});

const getters = {
    getSexos(state) {
        return state.sexos;
    },
    getTipoEntradas(state) {
        return state.tipoEntradas;
    },
    getTipoPagos(state) {
        return state.tipoPagos;
    },
    getTipoEventos(state) {
        return state.tipoEventos;
    },
    getTipoMembresias(state) {
        return state.tipoMembresias;
    },
    getEstadoCarnet(state) {
        return state.estadoCarnet;
    },
    getDias(state) {
        return state.dias;
    },
};

const actions = {};

const mutations = {

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
