import {axiosData} from "@/store/api/axios";

const state = () => ({
  listas: JSON.parse(localStorage.getItem('listas')) || null,
  lista: JSON.parse(localStorage.getItem('lista')) || null,

});

const getters = {
  getListas(state) {
    return state.listas;
  },
  getLista(state) {
    return state.lista;
  },
};

const actions = {
  setLista({ commit }, payload) {
    commit('SET_LISTA', payload);
    payload
        ? localStorage.setItem('lista', JSON.stringify(payload))
        : localStorage.removeItem('lista');
  },
  setListas({ commit }, payload) {
    commit('SET_LISTAS', payload);
    payload
        ? localStorage.setItem('listas', JSON.stringify(payload))
        : localStorage.removeItem('listas');
  },
  async getListaReserva(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'listareserva/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('listas/setListas', data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async dameporCodigo(_,codigo){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/codigo/'+codigo,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('listas/setListas', data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async dameListaReserva(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/reserva/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('listas/setListas', data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async ingresaListaFree(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/handleLoader', false);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/free',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false)
        this.dispatch('ui/handleLoader', true);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async ingresaReservaNegocio(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/reserva/nuevo',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('listas/setListas', data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
async getListaFiestaNegocio(_,payload){
  this.dispatch('ui/handleLoaderBtn', true);
  try {
    const data = await axiosData(
        true,
        'lista/fiesta/negocio',
        payload,
        'post',
        false,
        false
    );

    if(data){
      this.dispatch('ui/handleLoaderBtn', false);
      this.dispatch('listas/setListas', data);
      return data;
    }else{
      this.dispatch('ui/handleLoaderBtn', false);
      return  null;
    }
  } catch (error) {
    this.dispatch('ui/handleLoaderBtn', false);
    throw new Error(`Tunay api failed, error: ${error}`);
  }},
  async saveListaQr(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/qrcode',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('listas/setListas', data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async saveListaIngreso(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/ingreso',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('listas/setListas', data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async saveListaFeo(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/feo',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('listas/setListas', data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getListaFiestaExcel(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/excel',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('listas/setListas', data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},


  async getFiestasdelCliente(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/cliente/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getListaFiestaReportePromotor(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      let queryParams = '';
      if(payload.puertaFiltroRequest){
        // Obtener los filtros de tiempo desde el estado o de alguna fuente en el frontend
        const fromTime = payload.puertaFiltroRequest.fromTime;
        const toTime = payload.puertaFiltroRequest.toTime;

        // Construir la URL con los parámetros de consulta, si están presentes

        if (fromTime && toTime) {
          queryParams = `?fromTime=${fromTime}&toTime=${toTime}`;
        }
      }
      const data = await axiosData(
          true,
          `lista/fiesta/reporte/promotor/${payload.id}${queryParams}`,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getListaFiestaReporteCabeza(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      let queryParams = '';
      if(payload.puertaFiltroRequest){
        // Obtener los filtros de tiempo desde el estado o de alguna fuente en el frontend
        const fromTime = payload.puertaFiltroRequest.fromTime;
        const toTime = payload.puertaFiltroRequest.toTime;

        // Construir la URL con los parámetros de consulta, si están presentes

        if (fromTime && toTime) {
          queryParams = `?fromTime=${fromTime}&toTime=${toTime}`;
        }
      }
      const data = await axiosData(
          true,
          `lista/fiesta/reporte/cabeza/${payload.id}${queryParams}`,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},

  async getAsistenciaPromotor(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Reporte Promotores');

    try {
      const data = await axiosData(
          true,
          'lista/fiesta/reporte/promotores/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getListaFiestaReporteCoordinador(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      let queryParams = '';
      if(payload.puertaFiltroRequest){
        // Obtener los filtros de tiempo desde el estado o de alguna fuente en el frontend
        const fromTime = payload.puertaFiltroRequest.fromTime;
        const toTime = payload.puertaFiltroRequest.toTime;

        // Construir la URL con los parámetros de consulta, si están presentes

        if (fromTime && toTime) {
          queryParams = `?fromTime=${fromTime}&toTime=${toTime}`;
        }
      }
      const data = await axiosData(
          true,
          `lista/fiesta/reporte/coordinador/${payload.id}${queryParams}`,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getListaFiestaReporteGraph(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      let queryParams = '';
      if(payload.puertaFiltroRequest){
        // Obtener los filtros de tiempo desde el estado o de alguna fuente en el frontend
        const fromTime = payload.puertaFiltroRequest.fromTime;
        const toTime = payload.puertaFiltroRequest.toTime;

        // Construir la URL con los parámetros de consulta, si están presentes

        if (fromTime && toTime) {
          queryParams = `?fromTime=${fromTime}&toTime=${toTime}`;
        }
      }
      const data = await axiosData(
          true,
          `lista/fiesta/reporte/graph/${payload.id}${queryParams}`,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getListaFiestaReporteTipoLista(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      let queryParams = '';
      if(payload.puertaFiltroRequest){
        // Obtener los filtros de tiempo desde el estado o de alguna fuente en el frontend
        const fromTime = payload.puertaFiltroRequest.fromTime;
        const toTime = payload.puertaFiltroRequest.toTime;

        // Construir la URL con los parámetros de consulta, si están presentes

        if (fromTime && toTime) {
          queryParams = `?fromTime=${fromTime}&toTime=${toTime}`;
        }
      }
      const data = await axiosData(
          true,
          `lista/fiesta/reporte/tipolista/${payload.id}${queryParams}`,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getEventoSparkline(_, payload) {
    this.dispatch('ui/handleLoaderBtn', true);

    try {
      let queryParams = '';
      if(payload.puertaFiltroRequest){
      // Obtener los filtros de tiempo desde el estado o de alguna fuente en el frontend
      const fromTime = payload.puertaFiltroRequest.fromTime;
      const toTime = payload.puertaFiltroRequest.toTime;

      // Construir la URL con los parámetros de consulta, si están presentes

      if (fromTime && toTime) {
        queryParams = `?fromTime=${fromTime}&toTime=${toTime}`;
      }
      }
      // Hacer la solicitud GET con los parámetros en la URL
      const data = await axiosData(
          true,
          `lista/evento/sparkline/${payload.id}${queryParams}`,
          'get',
          false,
          false
      );

      if (data) {
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      } else {
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },


  async dameInscritosSorteos(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/sortear/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getListaFiestaReporteSpark(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/reporte/sparkline/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},

  async getListaFiestaReservaAsistencia(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/reporte/reserva/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getListaFiestaReserva(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/reserva/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getListaFiestaReporte(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/reporte/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async borrarListaPromotorFiesta(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/promotor/'+id,
          'delete',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('listas/setListas', data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},

  async borrarLista(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/'+id,
          'delete',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('listas/setListas', data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async vermisQrs(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      let pagina = payload.page - 1;
      const data = await axiosData(
          true,
          'lista/qrs/'+payload.id+'?page='+pagina+'&size=6',
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('listas/setListas', data.content);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async ingresaEnvioListaPromotorNegocioQR(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/qr/email',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async ingresaListaPromotorNegocioQR(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/qr',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async saveListaFiestaNueva(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/nuevo',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async saveListaFiestaGratis(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/gratis',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async damePorFiestaPromotorHash(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/promotor/hash/'+payload.fId+'/'+payload.nTg,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async damePorFiestaPromotor(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'lista/fiesta/promotor/'+payload.fiestaId+'/'+payload.promotorId,
          'get',
          false,
          false
      );
      this.dispatch('ui/handleLoaderBtn', false);

      if(data){

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }}
};

const mutations = {
  SET_LISTAS(state, payload) {
    state.listas = payload;
    localStorage.setItem('listas', JSON.stringify(payload));
  },
  SET_LISTA(state, payload) {
    state.lista = payload;
    localStorage.setItem('lista', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
